import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './LoginPage.css';
import { Button, Modal, Space, Alert } from 'antd';
import {ExclamationCircleFilled} from '@ant-design/icons';
import { useHistory, BrowserRouter, Route, Switch, useNavigate } from 'react-router-dom';




// Обертка для вашего приложения



axios.defaults.timeout = 10000;




function Authorization({ onLogin }) {
  const [activeTab, setActiveTab] = useState('login');
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false); // Добавляем состояние регистрации
  const [loading, setLoading] = useState(false); // Состояние загрузки
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('Token');
    if (token) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, []); // Пустой массив зависимостей гарантирует выполнение только один раз после монтирования компонента




  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };


  const handleLogin = async () => {
    try {
      setLoading(true); // Устанавливаем состояние загрузки при отправке запроса
      const userAppURL = window.location.hostname;
      const subdomain = userAppURL.split('.')[0] || 'public';
      const response = await axios.post(
        'https://learninglive.ru:5000/auth',
        { email, password, account: subdomain },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      setLoading(false);

      if (response.data && response.data.token) {
        // Успешная авторизация
        const token = response.data.token;
        const userid = response.data.userid; // предположим, что сервер возвращает userId
        const key = 'your_secret_key'; // Ваш секретный ключ, используемый для подписи токена
       
        //const ttt = jwt_decode(JSON.stringify(token, key));
        //console.log('dddddd', ttt);
    
       
        localStorage.setItem('Token', token);
        localStorage.setItem('UserId', userid); // сохранить userId в локальном хранилище
        localStorage.setItem('isLoggedIn', 'true'); // сохранить статус авторизации
        setIsLoggedIn(true); // Обновляем состояние авторизации
        onLogin(true);
        console.log('User authorized');
        navigate('/');
        //history.push('/');
        // Дополнительная обработка, например, редирект на другую страницу
      



      } else {
        // Неудачная авторизация
        localStorage.setItem('isLoggedIn', 'false'); // сохранить статус авторизации
        setIsLoggedIn(false); // Сбросить состояние авторизации
        onLogin(false);
        console.log('Authorization failed');
      }
    } catch (error) {
      // Обработка ошибок
      console.error('Error during authorization:', error.response ? error.response.error : error.message);
    } finally {
      setLoading(false); // Сбрасываем состояние загрузки после получения ответа
    }
  };



  
  
  
  const handleRegister = async (event) => {
    event.preventDefault();
    try {
      setLoading(true); // Устанавливаем состояние загрузки при отправке запроса
      //Успешная регистрация
      const userAppURL = window.location.hostname;
      const subdomain = userAppURL.split('.')[0] || '';
  
      const requestData = {
        account: subdomain,
        email: email,
        username: username,
        password: password
      };
  
      const response = await axios.put('https://learninglive.ru:5000/register', 
      { email, password, username, account: subdomain }, 
      {
        headers: {
          'Content-Type': 'application/json'
        }
      });
  
      console.log('User registered successfully:', response.data);
      // Все успешно
      setIsRegistered(true);


    } catch (error) {
      // Обработка ошибок
      console.log('Error during registration:', error);
    } finally {
      setLoading(false); // Сбрасываем состояние загрузки после получения ответа
    }
  };




  const handleRecovery = async (event) => {/* Оставшаяся логика восстановления*/};

  const validateEmail = (email) => /\S+@\S+\.\S+/.test(email);

  const handleLoginButtonClick = (e) => { e.preventDefault(); handleLogin(); };
  const handleRegisterButtonClick = (e) => { e.preventDefault(); handleRegister(e); };


  return (
    
    <div className="container">
      
      <div className="form-container">
        <div className="tab-group">
          <div className={activeTab === 'login' ? 'tab-header active' : 'tab-header'} onClick={() => handleTabClick('login')}>Войти</div>
          <div className={activeTab === 'register' ? 'tab-header active' : 'tab-header'} onClick={() => handleTabClick('register')}>Зарегистрироваться</div>
          <div className={activeTab === 'recovery' ? 'tab-header active' : 'tab-header'} onClick={() => handleTabClick('recovery')}>Отправить новый</div>
        </div>

        {activeTab === 'login' && (
          <form className="input-form" onSubmit={(e) => { e.preventDefault(); }}>
            <input type="text" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
            <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} autoComplete="current-password" />
            <button className="action-button" onClick={handleLoginButtonClick} disabled={!validateEmail(email)}>Войти</button>
          </form>
        )}

        {activeTab === 'register' && (
          <form className="input-form" onSubmit={(e) => { e.preventDefault(); }}>
            {isRegistered && ( // Проверяем переменную состояния isRegistered
              <Alert message="Вы успешно зарегистрированы! Авторизуйтесь!" type="warning" />
            )}
            <input type="text" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
            <input type="text" placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} />
            <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} autoComplete="current-password" />
            <button className="action-button" onClick={handleRegisterButtonClick}>Зарегистрироваться</button>
          </form>
        )}

        {activeTab === 'recovery' && (
          <form className="input-form" onSubmit={(e) => { e.preventDefault(); }}>
            <input type="text" placeholder="Email" />
            <button className="action-button" onClick={handleRecovery}>Отправить новый</button>
          </form>
        )}
      </div>
      
    </div>
    
  );
}

export default Authorization;

import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Upload, Table, Space, Modal } from 'antd';
import { UploadOutlined, AudioOutlined } from '@ant-design/icons';
import { Switch } from 'antd';
import axios from 'axios';

const Audio = () => {
  const [form] = Form.useForm();
  const [audioData, setAudioData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editData, setEditData] = useState(null);
  const [formData, setFormData] = useState({ id: '', description: '', visibility: false });


  const uploadAudio = () => {
    const token = localStorage.getItem('Token');
  
    const fetchData = async () => {
      try {
        const formData = new FormData();
        formData.append('file', form.getFieldValue('audioFile').file);
        formData.append('stext', form.getFieldValue('description'));
  
        const response = await axios.post('https://learninglive.ru:5000/api/audio', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            authorization: token
          }
        });
  
        // Вы можете обновить таблицу здесь после успешной загрузки, вызвав соответствующую функцию обновления данных в таблице
                            //const token = localStorage.getItem('Token');
                            //console.log('ВООТ ТОКЕЕЕН', token);
                          
                        
                            const fetchData = async () => {
                              try {
                                
                                const response = await axios.get('https://learninglive.ru:5000/api/audio', {
                                  headers: {
                                    authorization: token
                                  }
                                  //cancelToken: source.token // Передаем токен для возможности отмены запроса
                                });
                                setAudioData(response.data);
                                //console.log('ВООТ ДАТА', response.data);
                              } catch (error) {
                                if (axios.isCancel(error)) {
                                  console.log('Request canceled', error.message);
                                } else {
                                  console.error('Ошибка при получении данных:', error);
                                }
                              }
                            };
                          
                          
                            if (token) {
                              fetchData();
                            }


      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Request canceled', error.message);
        } else {
          console.error('Ошибка при загрузке данных:', error);
        }
      }
    };
  
    if (token) {
      fetchData(); // Вызываем функцию для загрузки данных
    }
    form.setFieldsValue({ description: '', audioFile: undefined });
    //console.log('кнопаем');
  };
  

  const handleEdit = (record) => {
    setEditData(record); // Сохраняем данные для редактирования
    form.setFieldsValue({
      id: record.id, // Заполняем поле "ID"
      description: record.stext, // Заполняем поле "Описание"
      visibility: record.svisible // Заполняем поле "Visibility"
    });
    setIsModalVisible(true); // Открываем модальное окно при нажатии на кнопку "Редактировать"
  };


  const handleSave = () => {
    const { id, description, visibility } = form.getFieldsValue();
   // Логика для удаления элемента
   //console.log(' ++++++IDDDDD', seditdata);
   console.log('Новые данные из полей модального окна:', id, description, visibility);
   const token = localStorage.getItem('Token');
  
   const fetchData = async () => {
     try {
       const formData = new FormData();
       //formData.append('file', form.getFieldValue('audioFile').file);
       //formData.append('stext', form.getFieldValue('description'));
       //console.log(' ++++++IDDDDD', seditdata);
       const response = await axios.put(`https://learninglive.ru:5000/api/audio/${id}`, {
        stext: description ,
        svisible: visibility
      }, {
        headers: {
           authorization: token
        }
    });
 
       // Вы можете обновить таблицу здесь после успешной загрузки, вызвав соответствующую функцию обновления данных в таблице
                           //const token = localStorage.getItem('Token');
                           //console.log('ВООТ ТОКЕЕЕН', token);
                           setIsModalVisible(false);
                       
                           const fetchData = async () => {
                             try {
                               
                               const response = await axios.get('https://learninglive.ru:5000/api/audio', {
                                 headers: {
                                   authorization: token
                                 }
                                 //cancelToken: source.token // Передаем токен для возможности отмены запроса
                               });
                               setAudioData(response.data);
                               //console.log('ВООТ ДАТА', response.data);
                             } catch (error) {
                               if (axios.isCancel(error)) {
                                 console.log('Request canceled', error.message);
                               } else {
                                 console.error('Ошибка при получении данных:', error);
                               }
                             }
                           };
                         
                         
                           if (token) {
                             fetchData();
                           }


     } catch (error) {
       if (axios.isCancel(error)) {
         console.log('Request canceled', error.message);
       } else {
         console.error('Ошибка при загрузке данных:', error);
       }
     }
   };
 
   if (token) {
     fetchData(); // Вызываем функцию для загрузки данных
   }



 };

  const handleDelete = (ID) => {
    // Логика для удаления элемента
    const token = localStorage.getItem('Token');
  
    const fetchData = async () => {
      try {
        const formData = new FormData();
        //formData.append('file', form.getFieldValue('audioFile').file);
        //formData.append('stext', form.getFieldValue('description'));
        console.log(' IDDDDD', ID);
        const response = await axios.delete(`https://learninglive.ru:5000/api/audio/${ID}`, {
          headers: {
             authorization: token
          }
        });
  
        // Вы можете обновить таблицу здесь после успешной загрузки, вызвав соответствующую функцию обновления данных в таблице
                            //const token = localStorage.getItem('Token');
                            //console.log('ВООТ ТОКЕЕЕН', token);
                            setIsModalVisible(false);
                        
                            const fetchData = async () => {
                              try {
                                
                                const response = await axios.get('https://learninglive.ru:5000/api/audio', {
                                  headers: {
                                    authorization: token
                                  }
                                  //cancelToken: source.token // Передаем токен для возможности отмены запроса
                                });
                                setAudioData(response.data);
                                //console.log('ВООТ ДАТА', response.data);
                              } catch (error) {
                                if (axios.isCancel(error)) {
                                  console.log('Request canceled', error.message);
                                } else {
                                  console.error('Ошибка при получении данных:', error);
                                }
                              }
                            };
                          
                          
                            if (token) {
                              fetchData();
                            }


      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Request canceled', error.message);
        } else {
          console.error('Ошибка при загрузке данных:', error);
        }
      }
    };
  
    if (token) {
      fetchData(); // Вызываем функцию для загрузки данных
    }



  };

  const handleCancel = () => {
    setIsModalVisible(false); // Закрываем модальное окно
  };

  // Функция для получения данных из API
  useEffect(() => {
    //let source = axios.CancelToken.source(); // Создаем источник для возможности отмены запроса
    const token = localStorage.getItem('Token');
    //console.log('ВООТ ТОКЕЕЕН', token);
  

    const fetchData = async () => {
      try {
        
        const response = await axios.get('https://learninglive.ru:5000/api/audio', {
          headers: {
            authorization: token
          }
          //cancelToken: source.token // Передаем токен для возможности отмены запроса
        });
        setAudioData(response.data);
        //console.log('ВООТ ДАТА', response.data);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Request canceled', error.message);
        } else {
          console.error('Ошибка при получении данных:', error);
        }
      }
    };
  
   
    if (token) {
      fetchData();
    }

    // Функция очистки, отменяющая предыдущий запрос при размонтировании компонента
    //return () => {
    //  source.cancel('Очистка предыдущего запроса');
    //};
  }, []);

  const columns = [
    // Определение столбцов для отображения полученных данных
    {
      title: 'Description',
      dataIndex: 'stext',
      key: 'stext'
    },
    {
      title: 'Visibility',
      dataIndex: 'svisible',
      key: 'svisible',
      render: (text) => (text ? 'Visible' : 'Not Visible')
    },
    {
      title: 'Date',
      dataIndex: 'sdate',
      key: 'sdate'
    },
    {
      title: 'Tag',
      dataIndex: 'tag',
      key: 'tag',
      render: (tag) => <div>{JSON.stringify(tag)}</div>
    },
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'Path',
      key: 'path',
      render: (record) => (
        <audio controls>
          <source src={record.path} type="audio/mp3" />
          Ваш браузер не поддерживает аудио тег.
        </audio>
      )
    },
    {
      title: 'Действия',
      key: 'action',
      render: (text, record) => (
        <Button onClick={() => handleEdit(record)}>Редактировать</Button>
      ),
    }
  ];
  
  

  return (
    <>
      <Form form={form} onFinish={uploadAudio}>
      <Form.Item name="description" label="Описание">
          <Input.TextArea placeholder="Введите описание" />
      </Form.Item>
        <Form.Item name="audioFile">
          <Upload beforeUpload={() => false} maxCount={1}>
            <Button icon={<UploadOutlined />}>Загрузить аудио файл</Button>
          </Upload>
        </Form.Item>
        <Form.Item shouldUpdate>
          {() => (
            <Button
              type="primary"
              htmlType="submit"
              disabled={!form.getFieldValue('description') || !form.getFieldValue('audioFile')}
            >
              Загрузить
            </Button>
          )}
        </Form.Item>
      </Form>
      <Table dataSource={audioData} columns={columns} rowKey="id" />
      <Modal title="Редактирование элемента" visible={isModalVisible} onCancel={handleCancel} footer={[
        <Button key="delete" onClick={() => handleDelete(editData.id)}>Удалить</Button>,
        <Button key="save" type="primary" onClick={handleSave}>Сохранить</Button>,
        <Button key="cancel" onClick={handleCancel}>Отмена</Button>,
      ]}>
        <Form form={form} onFinish={handleSave}>
    <Form.Item name="id" label="ID" initialValue={formData.id}>
      <Input readOnly />
    </Form.Item>
    <Form.Item name="description" label="Описание" initialValue={formData.description}>
      <Input.TextArea onChange={(e) => setFormData({ ...formData, description: e.target.value })} />
    </Form.Item>
    <Form.Item name="visibility" label="Visibility" valuePropName="checked" initialValue={formData.visibility}>
      <Switch onChange={(checked) => setFormData({ ...formData, visibility: checked })} />
    </Form.Item>
  </Form>
      </Modal>



    </>
  );
};

export default Audio;
import React, { useState, useEffect } from 'react';
import { Modal, Button, Table, Input } from 'antd';
import {  Link, useNavigate  } from 'react-router-dom';
//import { DownloadOutlined } from '@ant-design/icons';
//import { Button, Divider, Flex, Radio } from 'antd';
//import type { ConfigProviderProps } from 'antd';
import axios from 'axios'; // Don't forget to import axios
import { ThemesContext } from 'react-admin';

const WebinarRooms = () => {
  const [webinarData, setWebinarData] = useState([]);

  const [visible, setVisible] = useState(false);
  const [inputValue1, setInputValue1] = useState('');
  const [inputValue2, setInputValue2] = useState('');

  const navigate = useNavigate();

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    // Ваша логика обработки нажатия кнопки "Ок"
    console.log('Значение поля 1:', inputValue1);
    console.log('Значение поля 2:', inputValue2);
    setVisible(false);
  };

  const handleCancel = () => {
    // Ваша логика обработки нажатия кнопки "Отмена"
    setVisible(false);
  };


  useEffect(() => {
    async function fetchData() {
      const userId = localStorage.getItem('UserId');
      const token = localStorage.getItem('Token');

      if (userId && token) {
        const url = `https://learninglive.ru:5000/rooms/${userId}`;
        
        try {
          const response = await axios.get(url, {
            headers: {
              'authorization': `${token}`
            }
          });

          const data = response.data;

          if (data.success === false) {
            //console.log('An error occurred while retrieving project data: ' + data.message);
          } else {
            setWebinarData(data); // Set the retrieved webinar data
          }
        } catch (error) {
          console.error('An error occurred while fetching project data:', error);
        }
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    //console.log("Webinar data:", webinarData); // Log to check the webinar data
  }, [webinarData]);

  const handleButtonClick = (id) => {
    // Ваша логика обработки нажатия на кнопку
    console.log('Нажата кнопка с id:', id);
    
    // Здесь можно вызвать любую другую логику или функции, связанные с нажатием кнопки
  };

  const handleButtonClickLive = (id) => {
    navigate(`/web?id=${id}`);

  };

  const handleButtonClickSetup = (id) => {
    // Ваша логика обработки нажатия на кнопку
    

    // Ваша логика обработки нажатия на кнопку
   
      navigate(`/rooms/rooms_setup?id=${id}`);
    // Здесь можно вызвать любую другую логику или функции, связанные с нажатием кнопки
  
  };

  

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <div>
          <div>{text}</div>
          <Button type="primary"  onClick={() => handleButtonClickSetup(record.id)}>Настройка комнаты</Button>
          <span style={{ marginRight: '15px' }} />
          <Button onClick={() => handleButtonClick(record.id)}>Ссылка для зрителей</Button>
          <span style={{ marginRight: '15px' }} />
          <Button onClick={() => handleButtonClickLive(record.id)}>Перейти в эфир</Button>
          <span style={{ marginRight: '15px' }} />
          <Button type="dashed" onClick={() => handleButtonClick(record.id)}>Модерация</Button>
        </div>
      ),
    },
    {
      title: 'Autor',
      dataIndex: 'autor',
      key: 'autor',
    },
    // Add more column definitions as needed
  ];



  return (
    <div>
      <div>
      <h1>Вебинарные комнаты</h1>
      <Button type="primary" onClick={showModal} >Добавить</Button>
      <Table dataSource={webinarData} columns={columns} rowKey="id" style={{ width: '900px' }} />
      </div>
      <div>
      <Modal
        title="Создание новой комнаты"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Input style={{ marginBottom: '20px' }}  placeholder="Укажите название комнаты" value={inputValue1} onChange={(e) => setInputValue1(e.target.value)} />
        <Input placeholder="Укажите URL комнаты" value={inputValue2} onChange={(e) => setInputValue2(e.target.value)} />
      </Modal>

      </div>
    </div>
    
  );
};

export default WebinarRooms;

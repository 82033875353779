import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Input, Button, Upload, message, Avatar } from 'antd';
import { UserOutlined, IdcardOutlined, PictureOutlined, Loading3QuartersOutlined, CheckOutlined } from '@ant-design/icons';

function ProfilePage() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [telegramId, setTelegramId] = useState('');
  const [photo, setPhoto] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSave = async () => {
    setLoading(true);
    const token = localStorage.getItem('Token');
    try {

      const response = await fetch('https://learninglive.ru:5000/api/user', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${token}`
        },
        body: JSON.stringify({
          firstName: firstName,
          lastName: lastName,
          phone: phone,
          telegramId: telegramId,
          //photo: photo
        })
      });
      
      console.log(' СТАТУС', response.status);
      if (response.status === 200) {
        message.success('Profile saved successfully');
        
      } else {
        message.error('Failed to save profile');
        
        
      }
    } catch (error) {
      console.error(error);
      message.error('Failed to save profile');
    } finally {
      setLoading(false);
    }

    setTimeout(() => {
      setLoading(false);
      //message.success('Profile saved successfully');
    }, 1500);
  };

  const handleConnectTelegram = () => {
    // Telegram connection logic
  };

  const handlePhotoChange = info => {
    if (info.file.status === 'done') {
      setPhoto(info.file.originFileObj);
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} upload failed.`);
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem('Token');
      if (token) {
        try {
          const response = await fetch('https://learninglive.ru:5000/api/user', {
            headers: {
              Authorization: `${token}`,
            },
          });
          const userData = await response.json(); // Parsing response to JSON
          if (userData.users[0]?.foto?.data) {
            const photoUrl = arrayBufferToBase64(userData.users[0].foto.data);
            setPhoto(photoUrl);  // Setting the photo state with the fetched photo
          }
  
          
          setFirstName(userData.users[0].first_name);
          setLastName(userData.users[0].last_name);
          setPhone(userData.users[0].phone);
          //console.log(' Ура получили!', userData.users[0].email);
          //console.log('Вот фото', photoUrl);
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      } else {
        console.error('No token found in local storage');
      }
    };

    fetchUserData();
  }, []);

  const arrayBufferToBase64 = (buffer) => {
    let binary = '';
    let bytes = new Uint8Array(buffer);
    //console.log(' Вот байты ', buffer);
    for (let i = 0; i < bytes.length; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
  
    return `data:image/jpeg;base64,${window.btoa(binary)}`; // Assuming the image is JPEG, adjust accordingly if needed
  }





  return (
    <div style={{ width: '900px', margin: '0' }}>
      <h2>Ваш профиль</h2>
      <div style={{ display: 'flex', alignItems: 'center', margin: '20px 0 16px'  }}>
      <Avatar size={120} shape="square" icon={<UserOutlined />} src={photo} /> 
    </div>
      <Upload onChange={handlePhotoChange} showUploadList={false} beforeUpload={() => false}>
        <Button icon={<PictureOutlined />}>Загрузить фото</Button>
      </Upload>
      <div style={{ marginBottom: '10px' }}>
        <p>Имя:</p>
        <Input prefix={<UserOutlined />} value={firstName} onChange={e => setFirstName(e.target.value)} />
      </div>
      <div style={{ marginBottom: '10px' }}>
        <p>Фамилия:</p>
        <Input prefix={<UserOutlined />} value={lastName} onChange={e => setLastName(e.target.value)} />
      </div>
      <div style={{ marginBottom: '10px' }}>
        <p>Телефон:</p>
        <Input prefix={<IdcardOutlined />} value={phone} onChange={e => setPhone(e.target.value)} />
      </div>
      <div style={{ marginBottom: '10px' }}>
        <p>Telegram ID: {telegramId}</p>
        <Button onClick={handleConnectTelegram}>Connect Telegram</Button>
      </div>
      <Button type="primary" icon={loading ? <Loading3QuartersOutlined /> : <CheckOutlined />} onClick={handleSave} loading={loading}>
        Сохранить
      </Button>
    </div>
  );
}

export default ProfilePage;

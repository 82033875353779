import React, { useState, useEffect, useContext } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Authorization from './Authorization';
import Main from './Main';
import Web from './Webinars/Web';
import Freeaudio from './Page/Freeaudio';
import axios from 'axios';
import { AppContext } from './utils/AppContext';

function App() {
  const { setPostData } = useContext(AppContext);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const storedIsLoggedIn = localStorage.getItem('isLoggedIn');
    setIsLoggedIn(storedIsLoggedIn === 'true');

    const fetchData = async () => {
      if (storedIsLoggedIn === 'true') {
        const token = localStorage.getItem('Token');
        const userId = localStorage.getItem('UserId');
        try {
          const url = `https://learninglive.ru:5000/project/${userId}`;
          const response = await axios.get(url, {
            headers: {
              'authorization': `${token}`
            }
          });
          setPostData(response.data);
        } catch (error) {
          console.error('Ошибка при получении данных:', error);
          // Дополнительная обработка ошибки, например, разлогинивание пользователя
          setIsLoggedIn(false);
          localStorage.removeItem('Token');
          localStorage.removeItem('isLoggedIn');
          // Дополнительная навигация или обработка ошибки
        }
      }
    };
  
    fetchData();
  }, [setPostData]); // Убрана зависимость setIsLoggedIn, т.к. она автоматически обновится при изменении localStorage

  const handleLogin = () => {
    setIsLoggedIn(true);
    localStorage.setItem('isLoggedIn', 'true');
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    localStorage.setItem('isLoggedIn', 'false');
  };

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/main/*" element={<Main onLogout={handleLogout} />} />
          <Route path="/authorization" element={<Authorization onLogin={handleLogin} />} />
          <Route path="/web" element={<Web isAuthorized={isLoggedIn} onLogout={handleLogout} />} />
          <Route path="/freeaudio" element={<Freeaudio isAuthorized={isLoggedIn} onLogout={handleLogout} />} />
          <Route path="*" element={<Main onLogout={handleLogout} />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Button, Input } from 'antd';

const openInNewTab = () => {
  const url = 'https://example.com'; // Замените на нужный URL
  window.open(url, '_blank');
};

const Getutm = () => {
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    const token = localStorage.getItem('Token');

    const fetchData = async () => {
      try {
        const response = await axios.get('https://learninglive.ru:5000/api/getutm', {
          headers: {
            authorization: token
          }
        });
        setData(response.data);
        //console.log(response.data);
      } catch (error) {
        console.error('Ошибка при получении данных:', error);
      }
    };

    if (token) {
      fetchData();
    }
  }, []);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('');
  };

  const columns = [
    {
      title: 'Num Zak',
      dataIndex: 'num_zak',
      key: 'num_zak',
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search Num Zak"
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => confirm()}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type="primary"
            onClick={() => confirm()}
            icon="search"
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button onClick={() => clearFilters()} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </div>
      ),
      onFilter: (value, record) => record.num_zak.toString().toLowerCase().includes(value.toLowerCase()),
      onFilterDropdownOpenChange: visible => {
        if (visible) {
          const searchInput = document.querySelector('#search-input');
          if (searchInput) {
            setTimeout(() => searchInput.select(), 100);
          }
        }
      },
      render: text => (text ? <span>{text}</span> : text),
    },
    {
      title: 'In Source',
      dataIndex: 'in_source',
      key: 'in_source',
    },
    {
      title: 'In Medium',
      dataIndex: 'in_medium',
      key: 'in_medium',
    },
    {
      title: 'In Content',
      dataIndex: 'in_content',
      key: 'in_content',
    },
    {
      title: 'In Campaign',
      dataIndex: 'in_campaign',
      key: 'in_campaign',
    },
    {
      title: 'In Term',
      dataIndex: 'in_term',
      key: 'in_term',
    },
    {
      title: 'In Group',
      dataIndex: 'in_group',
      key: 'in_group',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Summa',
      dataIndex: 'summa',
      key: 'summa',
    },
    {
      title: 'Offer',
      dataIndex: 'offer',
      key: 'offer',
    },
    {
      title: 'GK Otvet',
      dataIndex: 'gk_otvet',
      key: 'gk_otvet',
      render: gk_otvet => {
        const parsed = JSON.parse(gk_otvet);
        const successValue = parsed.success;
        return <pre>{JSON.stringify(successValue, null, 2)}</pre>;
      },
    },
    {
      title: 'Indate',
      dataIndex: 'indate',
      key: 'indate',
    },
    {
      title: 'Открыть в ГК',
      dataIndex: 'num_zak',
      key: 'num_zak',
      render: () => (
        <Button onClick={openInNewTab} type="primary" target="_blank">
          Открыть
        </Button>
      ),
    },
  ];

  return (
    <div>
      <Input
        placeholder="Введите номер заказа"
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
      />
      <Table dataSource={data} columns={columns} rowKey="num_zak" />
    </div>
  );
};

export default Getutm;

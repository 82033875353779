//import React, { useEffect } from 'react';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Tabs } from 'antd';

function Rooms_setup() {

  const [activeTab, setActiveTab] = useState('tab1');

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };



  const tabs = [];
  for(let i = 1; i <= 9; i++) {
    tabs.push(
      <div key={i} className={activeTab === `tab${i}` ? 'tab-header active' : 'tab-header'} onClick={() => handleTabClick(`tab${i}`)}>
        Вкладка {i}
      </div>
    );
  }

  return (
    <div className="container" style={{ height: '750px', width: '900px', padding: '10px', backgroundColor: '#FFFFFF' }}>
      <div className="form-container" style={{ height: '700px' }}>
        <div className="tab-group"  >
          {tabs}
        </div>

        {/* Добавьте секции для каждой вкладки в зависимости от активной вкладки */}
        {activeTab === 'tab1' && (
          <form className="input-form" onSubmit={(e) => { e.preventDefault(); }} style={{border: '1px solid black', height: '550px'}}>
            {/* Содержимое для вкладки 1 */}
          </form>
        )}

        {activeTab === 'tab2' && (
          <form className="input-form" onSubmit={(e) => { e.preventDefault(); }}>
            {/* Содержимое для вкладки 2 */}
          </form>
        )}

        {activeTab === 'tab3' && (
          <form className="input-form" onSubmit={(e) => { e.preventDefault(); }}>
            {/* Содержимое для вкладки 2 */}
          </form>
        )}
        {activeTab === 'tab4' && (
          <form className="input-form" onSubmit={(e) => { e.preventDefault(); }}>
            {/* Содержимое для вкладки 2 */}
          </form>
        )}
        {activeTab === 'tab5' && (
          <form className="input-form" onSubmit={(e) => { e.preventDefault(); }}>
            {/* Содержимое для вкладки 2 */}
          </form>
        )}
        {activeTab === 'tab6' && (
          <form className="input-form" onSubmit={(e) => { e.preventDefault(); }}>
            {/* Содержимое для вкладки 2 */}
          </form>
        )}
        {activeTab === 'tab7' && (
          <form className="input-form" onSubmit={(e) => { e.preventDefault(); }}>
            {/* Содержимое для вкладки 2 */}
          </form>
        )}
        {activeTab === 'tab8' && (
          <form className="input-form" onSubmit={(e) => { e.preventDefault(); }}>
            {/* Содержимое для вкладки 2 */}
          </form>
        )}
        {activeTab === 'tab9' && (
          <form className="input-form" onSubmit={(e) => { e.preventDefault(); }}>
            {/* Содержимое для вкладки 2 */}
          </form>
        )}





      </div>
    </div>
  );
}


export default Rooms_setup;

import React, { useEffect, useRef, useState } from 'react';
import './VideoPlayer.css';

const VideoPlayer = (props) => {

  const { videoParams } = props;

  const playerRef = useRef(null);
  const player = useRef(null);
  const [currentTime, setCurrentTime] = useState(0);
  let intervalId;
  const [isMuted, setIsMuted] = useState(true);
  const [showGradient, setShowGradient] = useState(true);
  const [showTransparentLayer, setShowTransparentLayer] = useState(false);
  const [isGradientClicked, setIsGradientClicked] = useState(false);

  const handleGradientClick = () => {
    setIsGradientClicked(true);
    handlePlayVideo();
    console.log('НАЖАЛ');
  };

  const handlePlayVideo = () => {
    if (player.current && typeof player.current.unMute === 'function' && player.current.getPlayerState() !== -1) {
      console.log('ЗВУК');
      player.current.unMute();
      setIsMuted(false);
      setShowGradient(false);
      setShowTransparentLayer(true);
    }
  };

  useEffect(() => {
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    window.onYouTubeIframeAPIReady = () => {
      player.current = new window.YT.Player(playerRef.current, {
        videoId: 'JKSXSV9NkUc',
        playerVars: {
          controls: 0,
          disablekb: 1,
          modestbranding: 1,
          fs: 0,
          loop: 1,
          iv_load_policy: 3,
          autoplay: 1,
          mute: 1,
          rel: 0,
          showinfo: 0,
        },
        events: {
          onReady: (event) => {
            setShowGradient(true);
          },
          onStateChange: (event) => {
            if (event.data === window.YT.PlayerState.PLAYING && !intervalId) {
              intervalId = setInterval(() => {
                const time = player.current.getCurrentTime();
                setCurrentTime(time);
              }, 1000);
            } else if (event.data !== window.YT.PlayerState.PLAYING && intervalId) {
              clearInterval(intervalId);
            }
          },
        },
      });
    };

  }, []);

  const gradientStyle = {
    background: isGradientClicked ? 'linear-gradient(to bottom, rgba(0, 0, 0, 0.5), transparent)' : 'linear-gradient(to bottom, rgba(0, 0, 0, 0.5), transparent)',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 1,
    display: showGradient ? 'flex' : 'none',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  };

 

  const transparentLayerStyle = {
    display: showTransparentLayer ? 'block' : 'none',
  };

  const formatTime = (time) => {
    const days = Math.floor(time / (24 * 60 * 60));
    const hours = Math.floor((time % (24 * 60 * 60)) / (60 * 60));
    const minutes = Math.floor((time % (60 * 60)) / 60);
    const seconds = Math.floor(time % 60);

    return `${days}d ${hours}h ${minutes}m ${seconds}s`;
};
  
  // Где-то в компоненте:
  

  return (
    <div className="video-container2" style={{ position: 'relative' }}>
        <div style={gradientStyle} onClick={handleGradientClick}>
          <p style={{ color: 'white' }}>ДЛЯ ВКЛЮЧЕНИЯ ЗВУКА НАЖМИТЕ</p>
        </div>
        
        <div className="video-overlay" style={transparentLayerStyle} >
        </div>
        
        <div ref={playerRef} className="videoStyle" >
        </div>
      
    </div>
  );
};

export default VideoPlayer;

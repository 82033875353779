import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Upload, Table, Space, Modal } from 'antd';
import { UploadOutlined, AudioOutlined } from '@ant-design/icons';
import { Switch } from 'antd';
import axios from 'axios';
import './Freeaudio.css';
import moment from 'moment';



const Freeaudio = () => {
  const [audioData, setAudioData] = useState([]);
  
console.log('djjjn');

  useEffect(() => {
    
    const token = localStorage.getItem('Token');
    const subdomain = window.location.hostname.split(".")[0];    
    //console.log("Subdomain:", subdomain);
    const fetchData = async () => {
      
      try {
        
        const response = await axios.get('https://learninglive.ru:5000/api/freeaudio', {
          headers: {
            domen: subdomain,
          }
          //cancelToken: source.token // Передаем токен для возможности отмены запроса
        });
        setAudioData(response.data);
        console.log('ВООТ ДАТА', response.data);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Request canceled', error.message);
        } else {
          console.error('Ошибка при получении данных:', error); 
        }
      }
    };
  
   console.log(subdomain);
   //if (subdomain) {
    fetchData();
  //}
}, []); // Empty dependency array to run the effect only once
    
const columns = [
  {
    title: "Записи аудио ответов",
    dataIndex: "fullInfo",
    key: "fullInfo",
    render: (text, record) => {
      const date = moment(record.sdate);
      const formattedDate = date.format("DD/MM/YYYY"); // Формат: день/месяц/год (например, 01/01/2023)
      return (
      <div style={{ border: "1px solid black", borderRadius: "5px", padding: "5px", maxWidth: "500px", margin: "auto" }}>
        <div style={{ backgroundColor: '#ADD8E6', padding: '5px 10px', borderRadius: '15px', marginBottom: '10px' }}>
            <p style={{ margin: 0 }}>{formattedDate} </p>
        </div>       
        <p>Запрос: {record.stext}</p>
        <audio controls style={{ width: '100%', maxWidth: '100%' }}>
          <source src={record.path} type="audio/mp3" />
          Ваш браузер не поддерживает аудио тег.
        </audio>
      </div>
      );
    },
  },
];


    return (
      <div style={{ textAlign: "center" }}>
        <div>
          <h1>Аудио записи</h1>
        </div>
        <div style={{ border: "1px solid black", borderRadius: "5px", padding: "5px", maxWidth: "500px", margin: "auto" }}>
        <Table dataSource={audioData} columns={columns} rowKey="id" />
        </div>
      </div>
    );
  };

export default Freeaudio;     
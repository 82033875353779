import React, { useState, useEffect, useRef } from "react";
import Papa from "papaparse";
import axios from "axios";
import { Table, Select } from "antd";
import { Flex, Progress } from 'antd';

const { Option } = Select;

const twoColors = {
  '0%': '#108ee9',
  '100%': '#87d068',
};


const Deals = () => {
  const [ProgressNumber, setProgressNumber] = useState(0); // Используйте useState для объявления переменной состояния  
  const [csvData, setCsvData] = useState([]);
  const [displayedCsvData, setDisplayedCsvData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [defaultHeader, setDefaultHeader] = useState("-");
  const [selectedHeaders, setSelectedHeaders] = useState({});
  const batchSize = 50;

  const handleFileUpload = (event) => {
   
    const file = event.target.files[0];
    if (file) {
      Papa.parse(file, {
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
        complete: (result) => {
          const columnHeaders = result.meta.fields.map((field) => ({
            title: (
              <Select
                key={field}
                defaultValue={defaultHeader}
                style={{ width: 120 }}
                onChange={(value) => handleSelectChange(field, value)}
              >
                <Option value="-">-</Option>
                {result.meta.fields.map((field) => (
                  <Option key={field} value={field}>
                    {field}
                  </Option>
                ))}
              </Select>
            ),
            dataIndex: field,
            key: field,
          }));
          setColumns(columnHeaders);
          setCsvData(result.data);
          setDisplayedCsvData(result.data.slice(0, 2)); // Display only the first two rows
        },
      });
    }
  };

  const handleSelectChange = (field, value) => {
    setSelectedHeaders((prevSelectedHeaders) => ({
      ...prevSelectedHeaders,
      [field]: value,
    }));
  };

  const uploadToPostgres = () => {
    const filteredData = csvData.map((item) => {
      const filteredItem = {};
      for (const [key, value] of Object.entries(selectedHeaders)) {
        if (value !== "-") {
          filteredItem[key] = item[value];
        } else {
          filteredItem[key] = item[key];
        }
      }
      return filteredItem;
    });
    const userID = localStorage.getItem("UserId");
    const token = localStorage.getItem("Token");
    
    const LenProgres = filteredData.length;

    const uploadBatch = (start, end) => {
      const batch = filteredData.slice(start, end);
      axios
        .post("https://learninglive.ru:5000/api/deals", batch, {
          headers: {
            "deals": "add",
            "uuid": userID,
            "token": token,
          },
        })
        .then((response) => {
          
          const perm = (LenProgres / 100);
          console.log(`Uploaded batch from ${start + 1} to ${end}  ProgresNumber/perm = ${ProgressNumber}  ${perm}`);
          setProgressNumber(Math.round(end / perm));
          if (end < filteredData.length) {
            const newStart = end;
            const newEnd = Math.min(end + batchSize, filteredData.length);
            setTimeout(() => {
              uploadBatch(newStart, newEnd);
            }, 1000); // 1 second delay before sending the next batch
          } else {
            console.log("All data uploaded to PostgreSQL database");
            setProgressNumber(100);
          }
        })
        .catch((error) => {
          console.error("Error uploading data:", error);
        });
    };

    uploadBatch(0, batchSize);
  };

  useEffect(() => {
    setDefaultHeader("-");
  }, []);

  return (
    <div>
      <input type="file" accept=".csv" onChange={handleFileUpload} />
      <button onClick={uploadToPostgres}>Upload to Database</button>
      <div><Progress percent={ProgressNumber} strokeColor={twoColors} /></div>
      <div style={{ overflowX: "auto" }}>
        <Table dataSource={displayedCsvData} columns={columns} bordered />
      </div>
    </div>
  );
};

export default Deals;

import React, { useState, useEffect } from 'react';

const Customers = () => {


    return (

        <>Пользователия</>
    );
};


export default Customers

import React, { useEffect, useRef, useState } from 'react';


const WebNav = (props) => {

  const { navParams } = props;

return (
    <div style={{ height: '35px', backgroundColor: '#808080', display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0 10px' }}>
    <div style={{ textAlign: 'left', color: 'white' }}>Ведущие вебинара</div>
    <div>
      <button>Выйти</button>
    </div>
  </div>

);



}

export default WebNav;
import React, {useState, useEffect} from 'react';
import { Form, Input, Button, Typography, Checkbox, Row, Col } from 'antd';
import VideoPlayer from './Webvideo';
import WebNav from './Webnav';
import WebChat from './Webchat';
import Websales from './Websales';
import './ChatContainer.css'; 
import './VideoPlayer.css';
//import './ChatContainer.css'; // Добавьте эту строку в ваш код


const { Title, Text } = Typography;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const SubdomainContext = React.createContext(null);

const CenteredForm = () => {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [authCheckComplete, setAuthCheckComplete] = useState(false);
  const [id, setId] = useState('');
  const [customValue, setCustomValue] = useState('');
  //const [subdomain, setSubdomain] = useState('');

  useEffect(() => {

    // Парсинг параметров из URL
    const searchParams = new URLSearchParams(window.location.search);
    const idParam = searchParams.get('id');
    setId(idParam); // Устанавливаем переменную id из URL в состояние

    
    const subdomain = window.location.hostname.split('.')[0];
  

    //parseSubdomain(); // Вызов функции для извлечения поддомена

    console.log('УРАААААААЫ', idParam , subdomain);

    // получаем параметры комнаты


    // если нет параметров переадресуем на 403 страницу


    // Check the persistent storage for the authorization state
    const storedIsLoggedIn = localStorage.getItem('isLoggedIn');
    if (storedIsLoggedIn === 'true') {
      setIsAuthorized(true);
    }
    setAuthCheckComplete(true); // Устанавливаем флаг, что проверка авторизации завершена
  }, []);


  const onFinish = (values) => {
    console.log('Received values:', values);
    setIsAuthorized(true);
    localStorage.setItem('isLoggedIn', 'true');
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  //const videoId = '4_HJx9ujkr4';

  const chatParams = {
    // Параметры для чата
    username: 'User123',
    theme: 'light',
  };

  const videoParams = {
    // Параметры для видеоплеера
    autoplay: true,
    quality: 'high',
  };

  const navParams = {
    // Параметры для видеоплеера
    autoplay: true,
    quality: 'high',
  };
   
  const salesParams = {
    // Параметры для видеоплеера
    autoplay: true,
    quality: 'high',
  };


  //const containerStyleBar = {
  //  display: 'flex',
  //  flexDirection: 'column',
  //  width: '100%',
  //  height: '100%',
    
  //};
  //const containerStyle = {
  //  display: 'flex',
  //  flexDirection: 'column',
  //  justifyContent: 'top', 
  //  width: '60%',
    
 //};
    
 //const videoChatContainerStyle = {
 // display: 'flex',
 // flexDirection: 'row',
 // justifyContent: 'center',
  
 // height: '100%',
 // width: '100%',
  
//};

  const videowidthStyle = {
    width: '100%', /* Занимает всю доступную ширину */
    height: '100%',
    margin: '0 auto', /* Центрирование по горизонтали */
 };

   
      

 if (authCheckComplete && isAuthorized) {
  return (
    <div className="containerStyleBar">
      <WebNav navParams={navParams} />
      <div className="videoChatContainerStyle" >
        <div className="containerStyle" >
          <VideoPlayer videoParams={videoParams} />
        </div>
        <div className="chat-container" >
          <WebChat chatParams={chatParams} />
        </div>
      </div>
    </div>
  );
}

  if (authCheckComplete && !isAuthorized) {
  return (
    <Row justify="center" style={{ minHeight: '100vh' }}>
      <Col span={8}>
        <Form
          {...layout}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Title level={3}>Название вебинара</Title>
          <Text level={5}>Ведущие вебинара: Иван Иванов, Мария Петрова</Text>
          <Text level={5}>Дата проведения: 12 марта 2022</Text>
          <Form.Item
            label="Имя"
            name="name"
            rules={[{ required: true, message: 'Пожалуйста, введите ваше имя!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, type: 'email', message: 'Пожалуйста, введите корректный email!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Ваш телефон"
            name="phone"
            rules={[{ required: true, message: 'Пожалуйста, введите ваш телефон!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="remember"
            valuePropName="checked"
            wrapperCol={{ offset: 8, span: 16 }}
          >
            <Checkbox>Согласен с обработкой данных</Checkbox>
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              Войти в комнату
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
  };
};

export default CenteredForm;

import React, { useRef, useEffect } from 'react';
import Hls from 'hls.js';
import axios from 'axios';
import videojs from 'video.js'

const Screencast = () => {
  const videoRef = useRef(null);

  useEffect(() => {
    if (Hls.isSupported()) {
      const hls = new Hls();
      hls.loadSource('https://learninglive.ru:8443/live/live5555/index.m3u8');
      hls.attachMedia(videoRef.current);
      hls.on(Hls.Events.MANIFEST_PARSED, function () {
        videoRef.current.play();
      });
    } else if (videoRef.current.canPlayType('application/vnd.apple.mpegurl')) {
      videoRef.current.src = 'https://learninglive.ru:8443/live/live5555/index.m3u8';
      videoRef.current.addEventListener('loadedmetadata', function () {
        videoRef.current.play();
      });
    }
  }, []);

  return (
    <div>
      <video
        controls
        ref={videoRef}
        style={{ width: '100%', maxWidth: '800px' }}
      />
    </div>
  );
};

export default Screencast;

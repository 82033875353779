import React, { useState, useEffect } from 'react';
import { io } from 'socket.io-client';
import { Input, Button, Switch, Form, Row, Col } from 'antd';
//import 'antd/dist/antd.css';

const CHAT_ID = '11111';
const USER_EMAIL = 'user@example.com';
let socket; // Объявляем переменную для хранения экземпляра сокета

const Webroomchat = (props) => {
  const { chatParams } = props;
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [inputMessage, setInputMessage] = useState('');

  const onFinish = (values) => {
    console.log('Received values:', values);
  };

  // Функция отправки сообщения
  const sendMessage = () => {
    socket.emit('message', { email: USER_EMAIL, message: inputValue });
    //setInputValue('');

    // Напишите свою логику обработки отправки сообщения с использованием данных из inputMessage
    // Например, добавление нового сообщения в массив messages
    const newMessage = {text: inputMessage, timestamp: new Date()};
    setMessages([...messages, newMessage]);
    setInputMessage(''); // Очистить поле ввода после отправки сообщения
  };

  useEffect(() => {
    // Устанавливаем соединение с сокетом при монтировании компонента
    socket = io('https://learninglive.ru:5500', {
      withCredentials: true,
      extraHeaders: {
        "Access-Control-Allow-Origin": "https://dev.learninglive.ru:3000"
      }
    });

    // Подписываемся на прием сообщений
    socket.on('message', (message) => {
      setMessages((prevMessages) => [...prevMessages, message]);
    });

    // Закрываем соединение с сокетом при размонтировании компонента
    return () => {
      socket.close();
    };
  }, []); // Используем пустой массив зависимостей для запуска эффекта один раз

  return (
    <div className="chat-container22">
        <div className="chat-content333">
            {/* Здесь можно разместить содержимое чата */}
        </div>
        <div className="input-wrapper">
            <Form
                name="chat-form"
                layout="inline"
                onFinish={onFinish}
            >
                <Row gutter={16}>
                    <Col>
                        <Form.Item name="message" rules={[{ required: true, message: 'Please input your message!' }]}>
                            <Input placeholder="Enter your message" />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">Send</Button>
                        </Form.Item>
                    </Col>
                    <Col>
                       
                            <label>Включен запрет</label>
                           
                       
                    </Col>
                </Row>
            </Form>
        </div>
    </div>
);
};

export default Webroomchat;
